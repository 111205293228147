import React from "react"
import systembolaget from "../../static/systembolaget.svg"
import ek from "../../static/ek.png"
import logo from "../../static/uddacider.png"
import "./index.css"

const Ek = () => (
  <a href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3942801/">
    <img className="ek" src={ek}></img>
  </a>
)

const Contact = () => (
  <div style={{ padding: "20px" }}>
    <h3>Kontakt</h3>
    <a href="mailto:anton@uddacider.se">anton@uddacider.se</a>
  </div>
)

const Systembolaget = () => (
  <>
    <a href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3942801/">
      <img style={{ width: "200px" }} src={systembolaget} />
    </a>
  </>
)

export default function Home() {
  return (
    <>
      <div style={{ width: "100vw", overflow: "hidden" }}>
        {/* <img src={logo} alt="logo" className="logo" /> */}
        <div className="inner-wrapper">
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
          <Ek />
        </div>
      </div>
      <Systembolaget />
      <Contact />
    </>
  )
}
